<template>
  <v-card
    class="d-flex flex-column flex primary-gradient-bg-light-inverted"
    outlined
  >
    <IncomingMatchesTable class="d-flex flex-column flex" />
  </v-card>
</template>

<script>
import IncomingMatchesTable from "@/components/shared/IncomingMatchesTable.vue";

export default {
  name: "SecondaryLayout",
  components: {
    IncomingMatchesTable,
  },
};
</script>
