<template>
  <v-card
    class="flex primary-gradient-bg-light pa-5 align-self-stretch"
    outlined
  >
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "MainLayout",
};
</script>
